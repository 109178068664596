// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-magazine-post-tsx": () => import("../src/templates/magazine-post.tsx" /* webpackChunkName: "component---src-templates-magazine-post-tsx" */),
  "component---src-templates-magazine-category-tsx": () => import("../src/templates/magazine-category.tsx" /* webpackChunkName: "component---src-templates-magazine-category-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-magazine-tsx": () => import("../src/pages/magazine.tsx" /* webpackChunkName: "component---src-pages-magazine-tsx" */),
  "component---src-pages-magazine-category-all-tsx": () => import("../src/pages/magazine/category/all.tsx" /* webpackChunkName: "component---src-pages-magazine-category-all-tsx" */),
  "component---src-pages-mission-tsx": () => import("../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-our-daily-planet-annoucement-2021-tsx": () => import("../src/pages/our-daily-planet-annoucement-2021.tsx" /* webpackChunkName: "component---src-pages-our-daily-planet-annoucement-2021-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

